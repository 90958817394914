<template>
    <b-modal
      id="bills-to-receive-sus-modal"
      hide-header
      hide-footer
      centered size="lg"
      @hidden="onHidden"
      @show="onShow"
    >
      <div class="modal-header">
        <div class="modal-title">Nova entrada - Faturamento SUS</div>
        <span class="icon-box"><Close class="icon stroke" @click="closeModal"  /></span>
      </div>
      <div class="around-content">
        <validation-observer ref="billToReceiveForm">
          <div class="row">
  
            <div class="col-4">
                <label for="field_date" id="title-styles">
                  Data do atendimento
                  <span class="help" id="help">(opcional)</span>
                </label>
                <date-picker
                  class="full"
                  v-model="form.service_date"
                  format="DD/MM/YYYY"
                  placeholder="DD/MM/AAAA"
                  :clearable="false"
                  :lang="langDatePicker"
                ></date-picker>
            </div>
            
            <div class="col-8">
              <b-form-group>
                <label for="health_plan" id="title-styles">Convênio</label>
                <multiselect
                  disabled
                  id="health_plan"
                  v-model="form.health_plan"
                  track-by="id"
                  label="fantasy_name"
                  placeholder="Selecionar"
                  :options="healthPlans"
                  :searchable="true"
                  :allow-empty="false"
                  :option-height="40"
                  :showLabels="false"
                  :showNoResults="true"
                  class="with-border"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.fantasy_name }}
                  </template>
                  <template slot="noOptions">
                    <div>Digite para pesquisar seus convênios...</div>
                  </template>
                  <template slot="noResult">
                    <div>Nenhum convênio encontrado...</div>
                  </template>
                </multiselect>
                <div v-if="validated && (form.category && !['Depósito'].includes(form.category.name)) && !form.health_plan" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-form-group>
            </div>

            <div class="col-3">
              <b-form-group>
                <label for="field_amount" id="title-styles">Valor total</label>
                <money
                  disabled
                  class="form-control"
                  :value="form.amount"
                  @input="handleAmount"
                  :id="parseFloat(form.amount) < 0 ? 'red-border' : null"
                />
                <div v-if="validated && !form.amount" class="custom-invalid-feedback">Campo obrigatório</div>
                <div v-if="parseFloat(form.amount) < 0" class="custom-invalid-feedback">O valor deve ser positivo</div>
              </b-form-group>
            </div>
  
            <div class="col-3">
              <b-form-group>
                <label for="field_discount" id="title-styles">Desconto</label>
                <span class="help" id="help">(opcional)</span>
                <money
                  v-if="form.currency?.value === 'R$'"
                  class="form-control"
                  v-model.lazy="form.discount"
                />

                <b-form-input
                  v-else
                  autocomplete="off"
                  class="form-control"
                  v-model="form.discount"
                  :id="parseFloat(form.amount) < 0 ? 'red-border' : null"
                />
                <div v-if="parseFloat(form.discount) < 0" class="custom-invalid-feedback">O valor deve ser positivo</div>
              </b-form-group>
            </div>
  
            <div class="col-3">
              <b-form-group>
                <label for="currency" id="title-styles">Tipo</label>
                <span v-if="!form.discount" class="help" id="help">(opcional)</span>
                <multiselect
                  track-by="value"
                  label="label"
                  placeholder="Selecionar"
                  :options="currency"
                  :allow-empty="false"
                  :option-height="40"
                  :showLabels="false"
                  :showNoResults="false"
                  class="with-border"
                  v-model="form.currency"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.label }}
                  </template>
  
                  <template slot="noOptions">
                    Nenhuma opção
                  </template>
  
                  <template slot="noResult">
                    Nenhum resultado
                  </template>
                </multiselect>
                <div v-if="validated && form.discount && !form.currency" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-form-group>
            </div>
  
            <div class="col-3">
              <b-form-group>
                <label for="field_final_amount" id="title-styles">Valor final</label>
                <money
                  class="form-control"
                  :value="form.final_amount"
                  disabled
                />
                <div v-if="validated && !form.final_amount" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-form-group>
            </div>
  
            <div class="col-12">
              <div id="space-groups-column" class="description">
                <label for="field_description" id="title-styles">
                  Descrição
                  <span class="help" id="help">(opcional)</span>
                </label>
                <b-form-input
                  autocomplete="off"
                  v-model="form.description"
                  placeholder="Adicionar observações"
                />
              </div>
            </div>
          </div>
        </validation-observer>
  
        <Payments
          :useTax="false"
          :validated="validated"
          :bill="form"
        />
  
        <div :class="{ 'wrapper-button': true, deletable: form.id }">
          <!-- <b-button
            v-if="form.id"
            v-can="'FiConRec5'"
            class="wh-button danger"
            variant="danger-outline"
            size="lg"
            @click="onDeleteClick"
          >
              Apagar conta
          </b-button> -->
          <b-button
            v-if="!form.id"
            v-can="'FiConRec2'"
            class="wh-button"
            variant="primary"
            size="lg"
            @click="createBillToReceive"
          >
            Salvar conta a receber
          </b-button>
          <!-- <b-button
            v-else
            v-can="'FiConRec4'"
            class="wh-button"
            variant="primary"
            size="lg"
            @click="updateBillToReceive"
          >
            Atualizar conta a receber
          </b-button> -->
        </div>
      </div>
    </b-modal>
  </template>
  <script>
  import susManageApi from '@/modules/sus/manageGuides/api'
  import { track } from '@/utils/mixPanel'
  import { showInvoicingSus } from '@/utils/permissionsHelp'
  import { getCurrentClinic } from '@/utils/localStorageManager'

  export default {
    name: 'BillToReceiveModal',
    components: {
      ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
      Close: () => import('@/assets/icons/close.svg'),
      Payments: () => import('@/modules/financial/components/Payments'),
    },
    props: {
      billToReceiveId: String,
      billToReceive: Object,
    },
    data() {
      const clinic = getCurrentClinic()
      return {
        billToReceiveCopy: null,
        clinic: clinic,
        clinic_id: clinic.id,
        validated: false,
        errors: {},
        healthPlans: [],     
        form: this.getDefaultForm(),
        langDatePicker: {
          formatLocale: {
            weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
          }
        },
        currency: [{ label: 'R$', value: 'R$' }, { label: '%', value: '%' }],
      }
    },
    mounted() {
      this.getHealthPlans()
    },
    methods: {
      showInvoicingSus,
      getDefaultForm() {
        return {
          id: null,
          type: 'bill_to_receive',
          category: null,
          patient: null,
          beneficiary: null,
          description: null,
          appointment_id: null,
          health_plan: null,
          plan: null,
          service_date: new Date(),
          amount: 0,
          discount: 0,
          currency: { label: '%', value: '%' },
          final_amount: 0,
          bill_items: [],
          payments: [],
        }
      },
      async onShow() {},
      getHealthPlans() {
        this.api.getAllClinicHealthPlans(this.clinic.id)
        .then(res => {
          this.healthPlans = res.data.map(data => data.health_plan);
        })
        .catch(err => this.$toast.error(err.message));
      },
      handleAmount(value) {
        if (!value) return;
        this.form.amount = value;
      },
      parseFormData() {
        return {
          reference_id: this.form.reference_id,
          reference_type: this.form.reference_type,
          type: this.form.type,
          clinic_id: this.clinic.id,
          category_id: this.form.category.id,
          patient_id: this.form.patient?.id ?? null,
          beneficiary_id: this.form.beneficiary ? this.form.beneficiary.id : null,
          description: this.form.description,
          appointment_id: this.form.appointment ? this.form.appointment.id : null,
          service_date: this.moment(this.form.service_date).format('YYYY-MM-DD'),
          amount: this.form.amount,
          discount: this.form.discount,
          currency: this.form.currency ? this.form.currency.value : null,
          final_amount: this.form.final_amount,
          bill_items: this.form.bill_items,
          payments: this.form.payments.map(payment => {
            const paymentPayload = {
              id: payment.id,
              amount: payment.amount,
              due_date: payment.due_date,
              bank_account_id: payment.bank_account.id,
              payment_method_id: payment.payment_method.id,
              installments: payment.installments
            };
            
            if (payment.id) {
              paymentPayload.id = payment.id;
            }

            return paymentPayload
          }),
        };
      },
      validateValues(){
        const negativePayment = this.form.payments.filter(payment => parseFloat(payment.amount) < 0)
        return (
          parseFloat(this.form.amount) > 0 &&
          !negativePayment?.length &&
          ((this.form.discount && parseFloat(this.form.discount) > 0) || !!this.form.discount)
        )
      },
      async createBillToReceive() {
        if (!this.isValidForm()) return;
        if(!this.validateValues()){
          this.$toast.error('Os valores inseridos na conta devem ser positivos!');
          return
        }

        const data = this.parseFormData();
        const isLoading = this.$loading.show();
        try {
          await this.api.createBill(data);
          await susManageApi.finishSusBatch(data.reference_id)

          track('conta_a_receber', {
            clinic: this.clinic.name,
            total: this.form.final_amount
          });
  
          this.form.payments.map(payment => {
            track('metodo_pagamento', {
              clinic: this.clinic.name,
              payment_method: payment.payment_method.type,
              total: payment.amount
            });
          })
  
          this.clearForm();
          this.$toast.success('Conta a receber cadastrada com sucesso!');
          this.$bvModal.hide('bills-to-receive-sus-modal');
          this.$emit('reload');
          this.$emit('hide');
        } catch(err) {
          this.$toast.error(err.message);
        } finally {
          this.$router.push('/faturamento-sus/lotes-finalizados');
          isLoading.hide();
        }
      },
      clearForm() {
        this.form = this.getDefaultForm();
        this.validated = false;
      },
      isValidForm() {
        this.validated = true;
        return this.form.category &&
          this.form.final_amount &&
          this.form.payments.every(payment =>
            payment.payment_method &&
            payment.bank_account &&
            payment.amount &&
            payment.due_date &&
            payment.installments.length
          ) &&
          this.isValidSumAmount()
      },
      isValidSumAmount() {
        let total = 0
				this.form.payments.forEach(payment => {
					total += payment.amount
				});
        if (parseFloat(total.toFixed(2)) !== parseFloat(this.form.final_amount.toFixed(2))) {
          this.$toast.warning('A soma dos métodos de pagamento deve ser igual ao valor final')
          return false
        }
        return true
      },
      // isValidState(model) {
      //   return this.errors[model] !== true
      // },
      onHidden() {
        this.form = this.getDefaultForm()
        this.$emit('onHide')
      },
      closeModal() {
        this.clearForm();
        this.$bvModal.hide('bills-to-receive-sus-modal');
      },
      calculateFinalValue() {
        const amount = this.form.amount;
        const currency = this.form.currency;
        
        if (currency?.value === '%' && !this.form?.discount) return

        let discount = 0
        discount = parseFloat(currency?.value === '%' ? this.form?.discount?.replace(',', '.') : this.form.discount);

        if (!amount || !discount || discount <= 0 || !currency || !currency.value) {
          this.form.final_amount = amount;
          return;
        }
  
        const realDiscount = currency.value === 'R$' ? discount : (amount * discount) / 100;
        this.form.final_amount = amount - realDiscount;
      },
    },
    watch: {
      'form.amount': function(newAmount, oldAmount) {
        newAmount && newAmount !== oldAmount && this.calculateFinalValue();
      },
      'form.discount': function(newDiscount, oldDiscount) {
        newDiscount && newDiscount !== oldDiscount && this.calculateFinalValue();
      },
      'form.currency': function(newCurrency, oldCurrency) {
        newCurrency && newCurrency !== oldCurrency && newCurrency.value && this.calculateFinalValue();
      },
      billToReceive: async function(value) {
        if (value) {
          this.form = { ...this.form, ...value }
          setTimeout(() => {
            this.form.payments = value.payments.map(payment => ({ 
              ...payment,  
              bank_account: payment.bank_account,
              due_date: new Date(payment.due_date) 
            }))
          }, 300)
        }
      }
    }
  }
</script>
<style lang="scss">
  #bills-to-receive-sus-modal {
    .modal-body {
      padding: 0 !important;
      margin: 0 !important;
  
      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px !important;
  
        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: #525C7A;
        }
  
        .icon {
          width: 25px;
          fill: var(--neutral-500);
        }
      }
  
      .sus-wrapper {
        width: 100%;
        display: inline-flex;
        align-items: baseline;
  
        .input-sus {
          width: 100%;
        }
  
        .sus-actions {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 38px;
          border: 1px solid var(--neutral-300);
          margin-left: 8px;
          border-radius: 8px;
        }
  
        .sus-delete-icon {
          margin-left: 20px;
        }
      }
  
      .attached-file {
        display: inline-flex;
        align-items: center;
        background-color: #F4F5FB;
        padding: 8px 20px;
        border-radius: 8px;
        margin: -15px 8px 0 8px;
        color: var(--type-placeholder);
      }
  
      .sus-remove-file-icon {
        margin-top: 2px;
        margin-left: 10px;
        width: 24px;
        height: 24px;
        fill: var(--neutral-500);
        cursor: pointer;
      }
  
      .around-content {
        padding: 24px 24px 0 24px;
  
        .multiselect {
          .multiselect__single {
            color: #525C7A !important;
          }
        }
      }
  
    }
  
    .wrapper-button {
      width: 100%;
      display: inline-flex;
      margin: 20px 0 !important;
      justify-content: flex-end;
  
      &.deletable {
        justify-content: space-between;
      }
  
  
      button {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
  
        &.danger {
          color: var(--states-error);
        }
      }
    }
  }
  
  .description {
    margin-bottom: 20px;
  }
  
  
  
  #title-styles {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #0C1D59;
  }
  
  #help {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    color: #8696AC;
  }
  
  #hr {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  
  #space-groups {
    display: flex;
    flex-direction: row;
    margin: 16px 0 0 0;
    width: 100%;
  }
  
  #space-groups-column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  #red-border{
    border: 0.66px red solid !important;
    color: red !important;
  }
</style>
  